window.StoreConnect = window.StoreConnect || {};

document.addEventListener("DOMContentLoaded", () => {
  const tabTriggers = document.querySelectorAll("[data-tab-trigger]");
  [...tabTriggers].map((tab) => {
    tab.addEventListener("click", event => {
      const providerId = event.target.getAttribute("data-tab-trigger");

      if (providerId) {
        showPaymentForm(providerId);
      }
    });
  });

  const lastProviderElement = document.querySelector("[data-last-used-provider]");
  if (lastProviderElement) {
    const lastProviderId = lastProviderElement.getAttribute("data-last-used-provider");
    if (lastProviderId) {
      showPaymentForm(lastProviderId);
    }
  }
});

function showPaymentForm(providerId) {
  document
    .querySelectorAll("[data-tab]")
    .forEach((tab) => tab.classList.add("sc-hide"));
  document
    .querySelectorAll("[data-tab-trigger]")
    .forEach((tab) => tab.classList.remove("is-active"));

  const tab = document.querySelector(`[data-tab='${providerId}']`);
  const trigger = document.querySelector(`[data-tab-trigger='${providerId}']`);

  tab.classList.remove("sc-hide");
  trigger.classList.add("is-active");
  activateSurcharge(providerId);

  if (window.StoreConnect.showPaymentProviderCallbacks[providerId]) {
    window.StoreConnect.showPaymentProviderCallbacks[providerId].show();
  }
}

function activateSurcharge(providerId) {
  document
    .querySelectorAll(`[data-surcharge-id]`)
    .forEach((elem) => elem.classList.add("sc-hide"));

  const selector = `[data-surcharge-id='${providerId}']`
  document
    .querySelectorAll(selector)
    .forEach((elem) => elem.classList.remove("sc-hide"))

  const elem = document.querySelector(selector);
  const totalPayableElem = document.querySelector("[data-order-cart-total-payable]")
  const totalTaxElem = document.querySelector("[data-order-tax-amount]")

  if (elem) {
    setTotalPayable(elem.getAttribute("data-surcharge-cart-total-payable"));
    setTotalTax(elem.getAttribute("data-surcharge-cart-total-tax"));
  } else {
    if (totalPayableElem) {
      setTotalPayable(totalPayableElem.getAttribute("data-order-cart-total-payable"));
    }

    if (totalTaxElem) {
      setTotalTax(totalTaxElem.getAttribute("data-order-tax-amount"));
    }
  }
}

function setTotalPayable(amount) {
  document.
    querySelectorAll('[data-order-cart-total-payable]').
    forEach((elem) => {elem.innerHTML = amount});
}

function setTotalTax(amount) {
  document.
    querySelectorAll('[data-order-tax-amount]').
    forEach((elem) => { elem.innerHTML = amount });
}

window.StoreConnect.showPaymentProviderCallbacks = {};
